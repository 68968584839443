export default function() {
  $('[data-validate="all"]').each(function (i, el) {
    var form = $(el);

    function addError(el) {
      console.log('addError', el.prop('name'))
      var root = el.closest('[data-question-id]')

      if(root.attr('data-answer-id') != undefined) {
        root = root.parent().closest('[data-question-id]')
      }

      var label = root.find('[data-question-label]')
      if (root.attr('data-question-label') != undefined) {
        label = root
      }

      label.attr('data-marked-error', true).addClass('text-red-700')
    }

    form.on('submit', function(e){ 
      var errors = false;
      form.find('input[type="submit"]:disabled').attr('disabled', false)
      form.find('[data-marked-error="true"]').removeClass('text-red-700').attr('data-marked-error', false)

      form.find('input,textarea,select').not(':hidden,[type="hidden"],[type="button"],[type="submit"]').each(function(i, el){
        var input = $(el);

        var type = input.prop('tagName').toLowerCase() == 'input' ? input.prop('type') : input.prop('tagName').toLowerCase()
        var valid = true
        
        // If this field is in a hidden dependent or part of a loopable template, we skip it.
        console.log("Validation", input, input.closest('.visually-hidden'))
        if (input.closest('.visually-hidden').length > 0 || input.closest('[data-template]').length > 0 || !input.prop('required')) {
          return true;
        }

        switch(type) {
          case 'radio':
          case 'checkbox':
          // case 'select':
            valid = form.find('input[name="' + input.attr('name')+'"]:checked').length > 0
            if (!valid) { addError(input) }
            break;
          default:
            valid = !!$.trim(input.val())
            if(! valid) { addError(input) }
        }

        if(! valid) { errors = true }
      })

      if(errors == true) {
        e.preventDefault();
        alert("Please complete the required fields");
      }
    })
  })
}
