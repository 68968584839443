import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["countdown"]
  static values = {
    url: String,
    time: Number
  }

  connect() {
    console.log('timeout redirect Connected', this.timeValue, this.urlValue)

    if(this.timeValue) {
      setTimeout(() => {
        console.log('Redirecting to', this.urlValue)
        window.location.href = this.urlValue
      }, this.timeValue)
    }
    // setTimeout(this.updateCountdown, 1000)
  }

  // updateCountdown() {
  //   this.remaining ||= this.timeValue

  //   remaining =
  // }
}
