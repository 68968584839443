import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["country", 'state', 'province', 'other']

  connect() {
    console.group("Country State Select Connected");
    console.log('Country', this.countryTarget)
    console.log('State', this.stateTarget)
    console.log('Province', this.provinceTarget)
    console.log('Other', this.otherTarget)
    console.groupEnd();

    this.handleCountryChange()
  }

  handleCountryChange() {
    console.log('Country State Select handleCountryChange', this.countryTarget.value)
    const val = this.countryTarget.value

    if(val == 'US') {
      this.stateTarget.classList.remove('hidden')
      this.stateTarget.removeAttribute('disabled')

      this.provinceTarget.classList.add('hidden')
      this.provinceTarget.setAttribute('disabled', 'disabled')
      this.otherTarget.classList.add('hidden')
      this.otherTarget.setAttribute('disabled', 'disabled')
    } else if(val == 'CA') {
      this.provinceTarget.classList.remove('hidden')
      this.provinceTarget.removeAttribute('disabled')

      this.stateTarget.classList.add('hidden')
      this.stateTarget.setAttribute('disabled', 'disabled')
      this.otherTarget.classList.add('hidden')
      this.otherTarget.setAttribute('disabled', 'disabled')
    } else {
      this.otherTarget.classList.remove('hidden')
      this.otherTarget.removeAttribute('disabled')

      this.stateTarget.classList.add('hidden')
      this.stateTarget.setAttribute('disabled', 'disabled')
      this.provinceTarget.classList.add('hidden')
      this.provinceTarget.setAttribute('disabled', 'disabled')
    }
  }

  disconnect() {
    console.log('Country State Select disconnected')
  }
}
