import { Controller } from "@hotwired/stimulus"
import tippy from 'tippy.js/headless';

// Placement Options - https://atomiks.github.io/tippyjs/v6/all-props/#placement

export default class extends Controller {
  static targets = ["dropdown", "button"]
  static values = {
    placement: String,
    trigger: String,
    container: { type: String, default: 'body' },
  }

  connect() {
    console.log('Dropdown Connected')
    console.log('Placement', this.placementValue)
    const opts = {
      placement: 'top',
      // offset: [this.offsetxValue, this.offsetyValue],
      // trigger: this.triggerValue,
      content: this.dropdownTarget,
      interactive: true,
      render(instance) {
        const popper = document.createElement('div');
        popper.appendChild(instance.props.content);
        return {
          popper
        };
      },
    }

    if (this.hasOffsetxValue && this.hasOffsetyValue) {
      opts.offset = [this.offsetxValue, this.offsetyValue]
    }

    if (this.hasPlacementValue) {
      opts.placement = this.placementValue
    }

    if (this.hasTriggerValue) {
      opts.trigger = this.triggerValue
    }

    this.dropdownTarget.classList.remove('hidden')
    this.tippy = tippy(this.buttonTarget, opts);
    console.log('Dropdown – Tippy setup', this.tippy, opts)
  }

  disconnect() {
    console.log('Dropdown disconnected')

    if(this.tippy) {
      this.tippy.destroy();
    }
  }
}
