export default function() {
  console.log("Loopable launch")

  $('[data-template]').each(function(i, el) {
    $(el).find('input,select,textarea').attr('disabled', true)
  });

  $('[data-field-loopable="true"]').each(function(i, el){
    const root = $(el);

    root.on('click', '[data-field-loopable-remove]', function(e) {
      const b = $(e.target || e.currentTarget)
      const r = b.closest('[data-field-loopable]')
      const i = b.closest('[data-field-loopable-child]')
      const v = r.find('[data-field-loopable-value]')
      let val = parseInt(v.val())
      
      if (isNaN(val)) {
        return false;
      }

      val--

      if(confirm('Are you sure?')) {
        v.val(val)
        i.remove()

        r.find('[data-field-loopable-child]').not('[data-template] [data-field-loopable-child]').each(function (i, el) {
          $(el).find('input,label').each(function (ci, cel) {
            ['name','for'].map(attr => {
              const $element = $(cel);
              let attrVal = $element.prop(attr)
              if (attrVal != undefined) {
                $element.prop(attr, attrVal.replace(/\.\#\d *\./g, '.#' + i + '.'));
              }
            });
          });
        });

      }
      e.preventDefault();
    })

    root.find('[data-field-loop-button]').click(function(e){
      const b = $(e.target || e.currentTarget)
      const r = b.closest('[data-field-loopable]')
      const v = r.find('[data-field-loopable-value]')
      let val = parseInt(v.val())
      const t = r.find('[data-field-loopable-root]')
      let d = r.find('[data-template]').html()

      if (isNaN(val)) {
        val = 0
      }

      val++;
      d = d.replace(/\.#\./g, '.#'+val+'.')
      t.append(d);
      v.val(val);

      r.find('[data-field-loopable-child]').last().find('input,select,textarea').attr('disabled', null)

      if(r.find('[data-field-loopable-child]').length > 1) {
        r.find('[data-field-loopable-remove]').show()
      }

      e.preventDefault();
    });
  });

}
