/* eslint no-console:0 */
import "css/app"

require("./lib/jquery.js")
require("./lib/alpine.js")

require('@rails/ujs').start();

require("trix")
require("@rails/actiontext")
import { Application } from "@hotwired/stimulus"

import 'bootstrap/js/dist/util.js'
import 'bootstrap/js/dist/modal.js'

import SignaturePad from 'signature_pad/dist/signature_pad.js'
import Signable from './global/signable.js'
import ConflictCheck from './global/conflict_check.js'
import Loopable from './global/loopable.js'
import ConditionalAnswers from './global/conditional_answer'
import Validation from './global/validation'
import Tooltip from './global/tooltip'

import DropdownController from "./common_controllers/dropdown_controller"
import LawpayPaymentFormController from "./common_controllers/lawpay_payment_form_controller.js"
import TimeoutRedirectController from "./common_controllers/timeout_redirect_controller.js"
import CountryStateSelectController from "./common_controllers/country_state_select_controller.js";
import RichtextController from "./common_controllers/richtext_controller.js";

window.Stimulus = Application.start()
Stimulus.register("dropdown", DropdownController)
Stimulus.register("lawpay-payment-form", LawpayPaymentFormController)
Stimulus.register("timeout-redirect", TimeoutRedirectController)
Stimulus.register("country-state-select", CountryStateSelectController)
Stimulus.register("richtext", RichtextController)

window.Signable = Signable;
window.ConflictCheck = ConflictCheck;
window.Loopable = Loopable;
window.ConditionalAnswers = ConditionalAnswers;
window.Validation = Validation;

$(function() {
  Signable();
  ConflictCheck();
  Loopable();
  ConditionalAnswers();
  Validation();
  Tooltip();
});
