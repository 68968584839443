export default function() {
  $('[data-conditional-answer]').each(function (i, el) {
    var $el = $(el);
    // var question_id = $el.attr('data-conditional-question-id')
    var question_number = $el.attr('data-conditional-question-number')
    var question_type = $el.attr('data-conditional-question-type')
    // var answer_id = $el.attr('data-conditional-answer-id')
    var answer_number = $el.attr('data-conditional-answer-number')
    var question_suffux = question_type == "checkbox" ? '[]' : ''
    var question_inputs = $('[name="question_' + question_number + question_suffux + '"]')

    var check = function(event) {
      var isOnLoad = event === undefined
      var selected = $('[name="question_' + question_number + question_suffux + '"][value="' + answer_number + '"]:checked').length > 0
      var confirmed = true
      var hasChildrenWithValues = false
      var childrenValues = []
      var children = $el.find('input,select,textarea')

      children.each(function (i, el) {
        var isSelected = false
        if (['radio', 'checkbox'].includes($(el).attr('type'))) {
          isSelected = $("[name='" + $(el).attr('name') + "']:checked").val() != undefined
        } else {
          isSelected = ($(el).val() != '' && $(el).val() != $(el).attr('data-default-value'))
        }

        childrenValues.push(isSelected)

        // hasChildrenWithValues = (
        //   (
        //     ($(el).attr('type') == 'checkbox' || 
        //     $(el).attr('type') == 'radio') && $(el).is(':checked'))
        //   ) && (
        //     $(el).is(':checked') || ($(el).val() != '' && $(el).val() != $(el).attr('data-default-value'))
        //   )
      })
      console.log({childrenValues})
      hasChildrenWithValues = childrenValues.includes(true)

      if (selected == false && !isOnLoad && hasChildrenWithValues) {
        console.log("Confirming", $el, {
          event, selected, isOnLoad, hasChildrenWithValues, confirmed, 
          question_number, question_type, answer_number, question_suffux, question_inputs
        })
        confirmed = confirm('Are you sure you want to change this selection?  Any fields affected by this change will be cleared.')
      }

      if (selected == false && !isOnLoad && confirmed == true) {
        $el.find('input,select,textarea').each(function (i, el) {
          if ($(el).attr('type') == 'checkbox' || $(el).attr('type') == 'radio') {
            $(el).prop("checked", false);
          } else {
            if($(el).attr('data-default-value') != undefined) {
              $(el).val($(el).attr('data-default-value'))
            } else {
              $(el).val('')
            }
          }
          $el.find('[data-conditional-answer]').addClass('visually-hidden')
        })
      }

      if (confirmed) {
        $el.toggleClass('visually-hidden', !selected)
      }

      $('[data-conditional-answer]').each(function(i, el) {
        var $wrap = $(el)
        var shouldHide = $wrap.hasClass('visually-hidden')
        $wrap.find('input,select,textarea').attr('disabled', shouldHide)
      });
    }

    check()
    question_inputs.on('click change', check)
  })
}
