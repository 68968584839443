export default function() {

  if($('[data-conflict-detected]').length > 0) {
    const handleChange = () => {
      $('[data-conflict-detected]').toggle($('[name="consider_lawyer"]:checked').val() == "1");
    }

    $('[name="consider_lawyer"]').on('click change', handleChange);
    handleChange();
  }

}
