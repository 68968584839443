import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["form", "paymentData", "ccnumber", "cvc", "name", "postalCode", "expiry", 'address1', 'address2', 'city', 'state', 'province', 'stateOther', 'country', 'method', 'eCheckWrapper', 'creditWrapper', 'businessName', 'businessNameWrapper', 'firstName', 'firstNameWrapper', 'lastName', 'lastNameWrapper', 'accountTypePersonalChecking', 'accountTypeBusinessChecking', 'accountTypePersonalSavings', 'accountTypeBusinessSavings', 'accountNumber', 'routingNumber', 'eCheckNotice']

  static values = {
    creditEnabled: Boolean,
    echeckEnabled: Boolean,
    echeck: String,
    credit: String,
    echeck: String,
    email: String
  }

  checkEnabled() {
    return !!this.echeckEnabledValue
  }

  creditEnabled() {
    return !!this.creditEnabledValue
  }

  connect() {
    this._ensureUniqueIDs()
    const style = this._getStyle();

    console.log('connect echeck', this.creditValue, this.echeckValue)

    if (this.checkEnabled()) {
      this.echeckHostedFields = window.AffiniPay.HostedFields.initializeFields(
        {
          publicKey: this.echeckValue,
          fields: [
            {
              selector: `#${this.accountNumberTarget.getAttribute('id')}`,
              input: {
                type: "bank_account_number",
                css: style
              }
            },
            {
              selector: `#${this.routingNumberTarget.getAttribute('id')}`,
              input: {
                type: "routing_number",
                css: style
              }
            }
          ]
        },
        this.hostedFieldsCallBack
      );
    }

    if (this.creditEnabled()) {
      this.creditHostedFields = window.AffiniPay.HostedFields.initializeFields(
        {
          publicKey: this.creditValue,
          fields: [
            {
              selector: `#${this.ccnumberTarget.getAttribute('id')}`,
              input: {
                type: "credit_card_number",
                css: style
              }
            },
            {
              selector: `#${this.cvcTarget.getAttribute('id')}`,
              input: {
                type: "cvv",
                css: style
              }
            }
          ]
        },
        this.hostedFieldsCallBack
      );
    }

    if (this.creditEnabled() && this.checkEnabled()) {
      this.chooseCredit();
    } else if (this.creditEnabled()) {
      this.chooseCredit();
    } else if (this.checkEnabled()) {
      this.chooseCheck();
    }
  }

  chooseCredit() {
    this.paymentMethod = 'credit'
    this.creditWrapperTarget.classList.remove('hidden')
    this.eCheckWrapperTarget.classList.add('hidden')
    this.eCheckNoticeTarget.classList.add('hidden')
  }

  chooseCheck() {
    this.paymentMethod = 'eCheck'
    this.creditWrapperTarget.classList.add('hidden')
    this.eCheckWrapperTarget.classList.remove('hidden')
    this.eCheckNoticeTarget.classList.remove('hidden')
  }

  chooseAccountType() {
    if (this.accountTypePersonalCheckingTarget.checked || this.accountTypePersonalSavingsTarget.checked) {
      this.businessNameWrapperTarget.classList.add('hidden')
      this.firstNameWrapperTarget.classList.remove('hidden')
      this.lastNameWrapperTarget.classList.remove('hidden')
    }

    if (this.accountTypeBusinessCheckingTarget.checked || this.accountTypeBusinessSavingsTarget.checked) {
      this.businessNameWrapperTarget.classList.remove('hidden')
      this.firstNameWrapperTarget.classList.add('hidden')
      this.lastNameWrapperTarget.classList.add('hidden')
    }
  }

  submit(event) {
    event.preventDefault();
    console.log('LawPay Payment Form Submitted')

    if (this.creditEnabled() && this.paymentMethod == "credit" && this.creditHostedFields.getState()) {
      this.creditHostedFields.getPaymentToken({
        "name": this.nameTarget.value,
        "postal_code": this.postalCodeTarget.value,
        "exp_month": this._getExpiry()[0],
        "exp_year": this._getExpiry()[1],
        "address1": this.address1Target.value,
        "address2": this.address2Target.value,
        "city": this.cityTarget.value,
        "state": this._getState(),
        "country": this.countryTarget.value,
        "email": this.emailValue,
      }).then((result) => {
        console.log("LawPay Card Success", result.id, result, this.paymentDataTarget, this.formTarget)
        this.paymentDataTarget.value = JSON.stringify(result)
        this.formTarget.submit();
      }).catch(function (err) {
        console.log("Card", err);
      });
    }

    if (this.checkEnabled() && this.paymentMethod == "eCheck" && this.echeckHostedFields.getState()) {
      const tokenData = {
        "postal_code": this.postalCodeTarget.value,
        "address1": this.address1Target.value,
        "address2": this.address2Target.value,
        "city": this.cityTarget.value,
        "state": this._getState(),
        "country": this.countryTarget.value,
        "email": this.emailValue,
      }

      if (this.accountTypeBusinessCheckingTarget.checked) {
        tokenData.name = this.businessNameTarget.value
        tokenData.account_type = 'checking'
        tokenData.account_holder_type = 'business'
      }
      if (this.accountTypeBusinessSavingsTarget.checked) {
        tokenData.name = this.businessNameTarget.value
        tokenData.account_type = 'savings'
        tokenData.account_holder_type = 'business'
      }
      if (this.accountTypePersonalCheckingTarget.checked) {
        tokenData.given_name = this.firstNameTarget.value
        tokenData.surname = this.lastNameTarget.value
        tokenData.account_type = 'checking'
        tokenData.account_holder_type = 'individual'
      }
      if (this.accountTypePersonalSavingsTarget.checked) {
        tokenData.given_name = this.firstNameTarget.value
        tokenData.surname = this.lastNameTarget.value
        tokenData.account_type = 'savings'
        tokenData.account_holder_type = 'individual'
      }
      this.echeckHostedFields.getPaymentToken(tokenData).then((result) => {
        console.log("LawPay eCheck Success", result.id, result, this.paymentDataTarget, this.formTarget)
        this.paymentDataTarget.value = JSON.stringify(result)
        this.formTarget.submit();
      }).catch(function (err) {
        console.log("eCheck", err);
      });
    }
  }

  hostedFieldsCallBack(state) {
    console.log('hostedFieldsCallBack', state)
  }

  _ensureUniqueIDs() {
    this.randomId = (Math.random() + 1).toString(36).substring(7);

    if (this.ccnumberTarget.getAttribute('id') === null) {
      this.ccnumberTarget.setAttribute('id', `ccnumber_${this.randomId}`)
    }

    if (this.cvcTarget.getAttribute('id') === null) {
      this.cvcTarget.setAttribute('id', `cvc_${this.randomId}`)
    }

    if (this.accountNumberTarget.getAttribute('id') === null) {
      this.accountNumberTarget.setAttribute('id', `account_${this.randomId}`)
    }

    if (this.routingNumberTarget.getAttribute('id') === null) {
      this.routingNumberTarget.setAttribute('id', `routing_${this.randomId}`)
    }
  }

  _getStyle() {
    return {
      width: 'calc(100% - 2px)',
      height: "42px",
      'border-radius': '6px',
      'box-sizing': 'border-box',
      'border-style': 'solid',
      'background-color': '#fff',
      'border-width': '1px',
      'padding': '8px 12px',
      'font-size': '16px',
      'font-weight': '400',
      'line-height': '24px',
      'appearance': 'none',
      'border-color': "rgb(212, 212, 216)",
      'box-shadow': "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
      'margin': "1px 1px 0",
      ':focus': {
        'border-color': 'rgba(99, 102, 241, 1)',
        'box-shadow': 'rgb(255, 255, 255) 0px 0px 0px 0px, rgb(99, 102, 241) 0px 0px 0px 1px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px',
      }
    };
  }

  _getState() {
    if (this.stateTarget.getAttribute('disabled') == null) {
      return this.stateTarget.value
    }
    if (this.provinceTarget.getAttribute('disabled') == null) {
      return this.provinceTarget.value
    }
    if (this.stateOtherTarget.getAttribute('disabled') == null) {
      return this.stateOtherTarget.value
    }
  }

  _getExpiry() {
    const delims = ["/", "-", "_", "|", "\\", "*", "&", "~", "+", "=", ":", ";", ",", "."]
    let expiry = this.expiryTarget.value
    let exp_month = null
    let exp_year = null

    for (let delim = 0; delim < delims.length; delim++) {
      if (expiry.includes(delims[delim])) {
        exp_month = expiry.split(delims[delim])[0]
        exp_year = expiry.split(delims[delim])[1]
      }
    }

    if (exp_month === null && exp_year === null) {
      exp_month = expiry.substring(0, 2)
      exp_year = expiry.substring(2)
    }

    return [exp_month, exp_year]
  }

  disconnect() {
    console.log('LawPay Payment Form disconnected')
  }
}
